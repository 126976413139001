<template style="width:100%; height: 100%;">
  <div class="login">
    <div class="title">网站配置</div>
    <div class="login-form">
      <div class="form">
        <el-form
          :model="loginForm"
          ref="loginForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="用户名"
            prop="userName"
            :rules="[{ required: true, message: '用户名不能为空' }]"
          >
            <el-input v-model="loginForm.userName"></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            prop="passWord"
            :rules="[{ required: true, message: '密码不能为空' }]"
          >
            <el-input type="password" v-model="loginForm.passWord"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" type="primary" @click="handleLogin"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        userName: "",
        passWord: "",
      },
      checked: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let info = sessionStorage.getItem("whether");
          let isTrue = this.isLastDayOfMonth() //判读是否为月末
          if (info === "true") {
            this.loginForm.whether = true;
          }
          if(isTrue) {
            this.loginForm.whether = false
          }
          this.$API.Post("actionBar/login", this.loginForm).then((res) => {
            let status = 0;
            if (res.data === 2) {
              this.$message({
                type: "error",
                message: "密码错误",
              });
              sessionStorage.setItem("isLogin", "false");
              sessionStorage.setItem("status", status);
            } else if (res.data === 1 || res.data === 4) {
              if (res.data === 4) {
                status = 1;
              }
              this.$message({
                type: "success",
                message: "登陆成功",
              });
              sessionStorage.setItem("isLogin", "true");
              sessionStorage.setItem("status", status);
              this.$router.push({
                path: "/addNews",
              });
            } else if (res.data === 3) {
              this.$message({
                type: "error",
                message: "用户名错误",
              });
              sessionStorage.setItem("isLogin", "false");
              sessionStorage.setItem("status", status);
            }
          });
        } else {
          return false;
        }
      });
    },
    isLastDayOfMonth() {
      var flag = false;
      var date = new Date();

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var today = date.getDate();

      var new_year = year; //取当前的年份
      var new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        //如果当前大于12月，则年份转到下一年
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天

      var month_last_day = new Date(
        new_date.getTime() - 1000 * 60 * 60 * 24
      ).getDate();

      if (today == month_last_day) {
        console.log(123);
        flag = true;
      }
      return flag;
    },
  },
};
</script>

<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-color: #0e57a2;
  overflow: hidden;
}
.title{
  width: 40%;
  color: white;
  font-size: 4vh;
  text-align: center;
  margin-top: 13.8%;
  margin-left: 30%;
}
.login-form {
  width: 40%;
  height: 300px;
  margin-top: 20px;
  margin-left: 30%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  width: 80%;
}
.btn {
  width: 100%;
}
</style>
