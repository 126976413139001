<template>
  <div>
    <div class="title" style="padding: 1% 1% 0 1%">添加新闻信息</div>
    <div class="header-btn">
      <el-button type="primary" @click="addNewInfo">添加</el-button>
      <el-button type="primary" @click="addTabInfo">轮播图配置</el-button>
      <el-button type="primary" @click="editPassword">修改密码</el-button>
    </div>
    <!-- 列表内容 -->
    <div class="content">
      <el-table border stripe :data="newsList" style="width: 100%">
        <el-table-column type="index" label="序号" width="50">
          <template scope="scope">
            <span>{{(pageNum - 1) * pageSize + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片路径" width="280">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.imagePath">{{
              scope.row.imagePath
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="作者" width="150">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.author">{{
              scope.row.author
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发表时间" width="150">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.publishedTime">{{
              scope.row.publishedTime
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文章标题" width="300">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.title">{{
              scope.row.title
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文章内容" width="450">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.content">{{
              scope.row.content
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文章链接" width="280">
          <template slot-scope="scope">
            <span class="dot" :title="scope.row.articlePath">{{
              scope.row.articlePath
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        @prev-click="handlePrevClick"
        @next-click="handleNextClick"
        class="pagination"
        background
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 添加新闻信息 -->
    <el-dialog
      center
      title="添加新闻信息"
      :visible.sync="newsDialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        ref="newsDataRef"
        class="new-form"
        :rules="newsDataRules"
        :model="newsDataForm"
        label-width="80px"
      >
        <el-form-item prop="author" label="作者">
          <el-input
            placeholder="请输入作者"
            v-model="newsDataForm.author"
          ></el-input>
        </el-form-item>
        <el-form-item prop="publishedTime" label="创建时间">
          <el-date-picker
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd"
            v-model="newsDataForm.publishedTime"
            type="date"
            placeholder="选择创建时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="title" label="文章标题">
          <el-input
            placeholder="请输入文章标题"
            v-model="newsDataForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item prop="articlePath" label="文章链接">
          <el-input
            placeholder="请输入文章链接"
            v-model="newsDataForm.articlePath"
          ></el-input>
        </el-form-item>
        <el-form-item prop="content" label="文章内容">
          <el-input
            placeholder="请输入文章内容"
            type="textarea"
            v-model="newsDataForm.content"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-upload
        ref="upload"
        class="upload-demo"
        :class="noneBtnDealImg ? 'disUoloadSty' : ''"
        list-type="picture-card"
        :action="action"
        accept=".jpg,.png"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :on-change="dealImgChange"
        multiple
        :limit="1"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleAddNew">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      :modal="false"
      title="修改密码"
      center
      width="30%"
      class="whole-case-detail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="editPasswordVisible"
      :show-close="true"
      :before-close="handleCloseMesssage"
    >
      <el-form
        ref="passwordForm"
        :rules="passwordRules"
        :model="passwordForm"
        label-width="120px"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            style="width: 90%"
            :show-password="true"
            v-model="passwordForm.oldPassword"
            type="password"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            style="width: 90%"
            :show-password="true"
            v-model="passwordForm.newPassword"
            type="password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPasswordAgain">
          <el-input
            style="width: 90%"
            :show-password="true"
            v-model="passwordForm.newPasswordAgain"
            type="password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button style="width: 8rem" @click="confirmEdit"> 确认 </el-button>
          <el-button style="width: 8rem" @click="handleCloseMesssage">
            取消
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "AddNewsList",
  data() {
    return {
      // 添加表单
      newsDataForm: {
        imagePath: "",
        author: "",
        publishedTime: "",
        articlePath: "",
        title: "",
        content: "",
      },
      newsDialogVisible: false,
      action: "", //图片上传路径
      fileList: [], //图片显示路径
      newsList: [], //新闻表格
      newsDataRules: {
        author: [{ required: true, message: "请输入作者", trigger: "blur" }],
        publishedTime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        articlePath: [
          { required: true, message: "请输入文章链接", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入文章内容", trigger: "blur" },
        ],
      },
      pageNum: 1,
      pageSize: 13,
      total: 0,
      isAdd: null, //true 添加 false 修改
      editPasswordVisible: false,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        newPasswordAgain: "",
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
        ],
        newPasswordAgain: [
          { required: true, message: "确认新密码不能为空", trigger: "blur" },
        ],
      },
      status: 0,
      noneBtnDealImg:false,
    };
  },
  mounted() {
    //判断是否需要修改密码
    this.status = +sessionStorage.getItem('status')
    if(this.status === 1) {
      this.editPasswordVisible = true
    }
    this.getNewsList();
    //上传地址
    this.action =
      initConfig(process.env.NODE_ENV === "production").$Config.baseUrl +
      "information/upload";
  },
  methods: {
    confirmEdit() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          if (
            this.passwordForm.newPassword !== this.passwordForm.newPasswordAgain
          ) {
            if (
              this.passwordForm.newPassword !==
              this.passwordForm.newPasswordAgain
            ) {
              this.$message({
                type: "warning",
                message: "新密码输入不一致",
              });
              return;
            }
            return;
          }

          this.$API.Post("actionBar/modifyPassword", {
            oldPassWord: this.passwordForm.oldPassword,
            passWord: this.passwordForm.newPassword,
          }).then(res => {
            console.log(res);
            if(res.succ) {
              this.$message({
                type: "success",
                message: "修改密码成功,请重新登陆",
              });
              sessionStorage.setItem('whether', 'true')
              this.$router.push('/login')
              // this.status = 0
              // this.editPasswordVisible = false
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          })
        } else {
          this.$utils.message("error", "请按照正确的格式输入！");
        }
      });
    },
    handleCloseMesssage() {
      if(this.status === 1) {
        this.$message({
          type: 'warning',
          message: '请修改密码'
        })
        return
      }
      this.editPasswordVisible = false
    },
    editPassword() {
      this.editPasswordVisible = true;
    },
    addTabInfo() {
      this.$router.push("/addTab");
    },
    handleRemove() {
      this.newsDataForm.imagePath = "";
      console.log("yyyyy---", this.fileList.length)
      this.noneBtnDealImg = this.newsDataForm.imagePath !== "";
      console.log("yyyyy", this.noneBtnDealImg)
    },
    dealImgChange(){
      this.noneBtnDealImg = this.newsDataForm.imagePath !== "";
      console.info("xxxxxx-----", this.fileList.length);
      console.info("xxxxxx", this.noneBtnDealImg);
    },
    getNewsList() {
      this.$API
        .Get("information/queryInformation", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.succ) {
            this.newsList = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    addNewInfo() {
      //修改需要传id
      this.newsDataForm.id = "";
      this.isAdd = true;
      this.newsDialogVisible = true;
    },
    handleClose() {
      this.$refs.upload.clearFiles();
      this.$refs.newsDataRef.resetFields();
      this.newsDialogVisible = false;
      this.noneBtnDealImg = false;
      this.newsDataForm.imagePath = "";
    },
    handleSuccess(response, file, fileList) {
      this.newsDataForm.imagePath = response;
      this.fileList = fileList;
    },
    handleAddNew() {
      if(this.newsDataForm.articlePath.indexOf("http") !== 0){
        this.$message({
          type: "error",
          message: "文章链接输入有误，请重新输入!",
        });
        return;
      }
      if (this.newsDataForm.imagePath === "") {
        this.$message({
          type: "error",
          message: "请先添加图片!",
        });
        return;
      }
      this.$refs.newsDataRef.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.$API.Post("information/add", this.newsDataForm).then((res) => {
              if (res.succ) {
                this.getNewsList();
                this.noneBtnDealImg = false;
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.handleClose();
              } else {
                this.$message({
                  type: "error",
                  message: "添加失败!",
                });
              }
            });
          } else {
            this.$API
              .Post("/information/modify", this.newsDataForm)
              .then((res) => {
                if (res.succ) {
                  this.getNewsList();
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.handleClose();
                } else {
                  this.$message({
                    type: "success",
                    message: "修改失败!",
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    handlePrevClick(pageNum) {
      this.pageNum = pageNum;
      this.getNewsList();
    },
    handleNextClick(pageNum) {
      this.pageNum = pageNum;
      this.getNewsList();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getNewsList();
    },
    handleEdit(index, row) {
      this.newsDataForm.id = row.id;
      this.isAdd = false;
      this.fileList = [];
      this.newsDialogVisible = true;
      this.newsDataForm.imagePath = row.imagePath;
      this.newsDataForm.articlePath = row.articlePath;
      this.newsDataForm.author = row.author;
      this.newsDataForm.content = row.content;
      this.newsDataForm.publishedTime = row.publishedTime;
      this.newsDataForm.title = row.title;
      this.fileList.push({ name: row.id, url: row.imagePath });
      this.noneBtnDealImg = this.fileList.length >= 1
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$API
            .Get("information/delete", {
              id: row.id,
            })
            .then((res) => {
              console.log("添加新闻", res);
              if (res.succ) {
                this.getNewsList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.header-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0 1%;
}
.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.content {
  padding: 1%;
}
.new-form {
  margin-top: 20px;
}
.dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagination {
  margin-top: 4vh;
  margin-left: 50%;
  transform: translateX(-12%);
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.disUoloadSty /deep/ .el-upload{
  display:none;   /* 上传按钮隐藏 */
}
</style>