<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <!--AI中台-->
      <img src="../../assets/image/u0.png" class="image-header" alt="" @click="selectMiddleGround('DataCapacity', 1, 0)"/>
      <!--数据中台-->
      <div class=" middleground-content">
        <img src="../../assets/image/u17.png" alt="" />
      </div>
      <div class="middleground-item-dev">
        <div class="middleground-item" v-for="item in middlegroundList" :key="item.id" @click="selectMiddleGround('DataCapacity', 1, 0)">
          <img :src="item.imagePath" alt="" />
          <div style="padding: 10px 20px;line-height: 35px">
            <div style="font-size: 2.2vh; font-weight: bold">{{ item.caption }}</div>
            <div style="font-size: 1.6vh;font-weight: 600;color: #818080;letter-spacing: 1px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{ item.description }}</div>
            <div style="color: #0067D0;font-size: 1.5vh;font-weight: 600;text-align: right;">查看更多>></div>
          </div>
        </div>
      </div>
      <shareCode></shareCode>
      <!--核心产品-->
      <div class="core-product" style="position: relative">
        <div>
          <img src="../../assets/image/coreProduct.png" style="width: 100%;">
        </div>
        <div class="core-product-content" style="position: absolute; top: 0px;left: 44%;">
          <div class="core-product-content-text">核心产品</div>
          <img src="../../assets/image/u86.png" style="width: 230px;height: 5px" alt="" />
        </div>
        <img src="../../assets/image/辅助决策.png" style="width: 11%;position: absolute;top: 21%;left: 46%;cursor: pointer;" alt="" @click="selectMiddleGround('CoreProduct', 2, 0)"/>
        <div style="position: absolute;top: 29%;left: 49.5%;color: white;font-size: 20px;font-weight: 600;cursor: pointer;" @click="selectMiddleGround('CoreProduct', 2, 0)">机器换人</div>
        <div style="position: absolute;top: 29%;left: 58%;color: white;font-size: 16px;">机器代替人工，解放人力，提升服务质量</div>
        <img src="../../assets/image/辅助业务.png" style="width: 13%;position: absolute;top: 43%;left: 53%;cursor: pointer;" alt="" @click="selectMiddleGround('CoreProduct', 2, 1)"/>
        <div style="position: absolute;top: 53%;left: 57.5%;color: white;font-size: 20px;font-weight: 600;cursor: pointer;" @click="selectMiddleGround('CoreProduct', 2, 1)">辅助业务</div>
        <div style="position: absolute;top: 53%;left: 67%;color: white;font-size: 16px;">人工智能赋能业务流程，提升业务处理质效</div>
        <img src="../../assets/image/机器换人.png" style="width: 11%;position: absolute;top: 69%;left: 46%;cursor: pointer;" alt="" @click="selectMiddleGround('CoreProduct', 2, 2)"/>
        <div style="position: absolute;top: 78%;left: 49.5%;color: white;font-size: 20px;font-weight: 600;cursor: pointer;" @click="selectMiddleGround('CoreProduct', 2, 2)">辅助决策</div>
        <div style="position: absolute;top: 78%;left: 58%;color: white;font-size: 16px;">挖掘数据深度价值，辅助精准决策</div>
      </div>
      <!--解决方案-->
      <div class="coreProduct-content">
        <img src="../../assets/image/u22.png" alt="" />
      </div>
      <div class="coreProduct-item-dev">
        <div class="coreProduct-item" v-for="(item, index) in coreProductList" :key="item.id" @click="selectMiddleGround('Solution', 3, index)">
          <img :src="item.imagePath" alt="" />
          <div class="core-product-caption">
            <p>AI +</p>
            <p>{{ item.caption }}</p>
          </div>
          <div class="core-product-description">
            <div>简介：</div>
            <div>{{ item.description }}</div>
            <div style="color: #0067D0;font-weight: 600;text-align: right;position: absolute;bottom: 20px;right: 20px;">查看更多>></div>
          </div>
        </div>
      </div>
      <!--合作伙伴-->
      <div class="partner">
        <img src="../../assets/image/u26.png" alt="" />
      </div>
      <div class="partner-carousel">
        <div class="partner-item" v-for="item in partnerImageList" :key="item.id">
          <img :src="item.imagePath" alt="" />
        </div>
      </div>
      <footer-content></footer-content>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue'; 
  import shareCode from '../../components/share.vue';
  export default {
    name: 'Index',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        //数据中台列表
        middlegroundList:[],
        //解决方案
        coreProductList: [],
        //合作伙伴
        partnerImageList: [],
      }
    },
    mounted() {
      this.queryMiddleground();
      this.querycoreProduct();
      this.queryPartnerImage();
    },
    methods: {
      queryMiddleground(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 1}).then((res) => {
          if (res.succ) {
            this.middlegroundList = res.data;
          }
        });
      },
      querycoreProduct(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 3}).then((res) => {
          if (res.succ) {
            this.coreProductList = res.data;
          }
        });
      },
      queryPartnerImage(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 9}).then((res) => {
          if (res.succ) {
            this.partnerImageList = res.data;
          }
        });
      },
      selectMiddleGround(routerName, activeIndex, secondMenuIndex){
        sessionStorage.setItem('activeIndex', activeIndex);//重点在这里
        sessionStorage.setItem('secondMenuIndex', secondMenuIndex);//重点在这里
        this.$router.push({
          name: routerName,
          params: {activeIndex: activeIndex}
        });
      }
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #F3F6F7;
  }
  .image-header {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .middleground-content{
    height: 110px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .middleground-item-dev{
    width: 70%;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 70px;
  }
  .middleground-item {
    width: 30%;
    cursor: pointer;
    margin: 0% 1% 1.7% 1%;
    transform: translateY(0px);
    background-color: white;
  }
  .middleground-item:hover{
    transform: scale(1.02, 1.02);
  }
  .middleground-item img{
    width: 100%;
  }
  .core-product{
    width: 100%;
  }
  .core-product img{
    width: 100%;
  }
  .coreProduct-content{
    height: 110px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .core-product-content{
    height: 110px;
    margin-top: 70px;
    text-align: center;
    color: white;
  }
  .core-product-content-text{
    font-size: 35px;
  }
  .coreProduct-item-dev{
    width: 70%;
    margin-top: 10px;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 70px;
  }
  .coreProduct-item {
    width: 45%;
    cursor: pointer;
    margin: 0% 0% 1.7% 4%;
    transform: translateY(0px);
    display: flex;
    background-color: white;
    position: relative;
  }
  .coreProduct-item:hover{
    transform: scale(1.02, 1.02);
  }
  .coreProduct-item img{
    width: 50%;
  }
  .core-product-caption{
    position: absolute;
    top: 35%;
    width: 25%;
    color: white;
    text-align: center;
  }
  .core-product-caption p{
    font-size: 25px;
  }
  .core-product-description{
    padding: 3vh 2vh;
    font-size: 1.5vh;
    line-height: 3vh;
  }
  .core-product img{
    width: 15%;
  }
  .partner{
    height: 110px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .partner-carousel{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 50px;
  }
  .partner-item{
    width: 18%;
    margin: 1%;
    display: flex;
    align-items: center;
  }
  .partner-item img{
    width: 100%;
    max-height: 100%;
  }
</style>