<template>
  <div class="header-title">
    <div class="content">
      <div class="title-image">
        <img src="../assets/image/title2.png" style="width: 100%"/>
      </div>
      <div class="header-tab">
        <a class="tab" :class="index === activeIndex ? 'active' : ''" v-for="(item, index) in tabData" :key="index" @click="changeClick(index, item)">{{item.title}}</a>
      </div>
    </div>
    <el-carousel :interval="5000" :height="activeItem.isFullScreen === 1 ? '100vh' : '72vh'">
      <el-carousel-item v-for="item in backImageList" :key="item.id">
        <img :src="item.url" class="image-header" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'HearderTitle',
  data() {
    return {
      tabData: [],
      backImageList: [], //背景图列表
      activeIndex: 0,
      activeItem: {}
    }
  },
  mounted() {
    if(sessionStorage.getItem("activeIndex")){
      this.activeIndex = parseInt(sessionStorage.getItem("activeIndex"));
      this.activeItem = sessionStorage.getItem("activeItem");
    }
    if(!this.activeIndex){
      this.activeIndex = 0
    }
    this.getTabInfo()
  },
  methods: {
    changeClick(index, item) {
      if(index === this.activeIndex){
        return;
      }
      sessionStorage.setItem('activeIndex', index);//重点在这里
      sessionStorage.setItem('activeItem', item);//重点在这里
      this.backImageList = this.tabData[this.activeIndex].imageList
      switch (index) {
        case 0:
          this.$router.push({
            name: "Index",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 1:
          this.$router.push({
            name: "DataCapacity",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 2:
          this.$router.push({
            name: "CoreProduct",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 3:
          this.$router.push({
            name: "Solution",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 4:
          this.$router.push({
            name: "MainContent",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 5:
          this.$router.push({
            name: "JoinUs",
            params: {activeIndex: this.activeIndex, }
          });
          break;
        case 6:
          this.$router.push({
            name: "AboutUs",
            params: {activeIndex: this.activeIndex, }
          });
          break;
      }
    },
    getTabInfo() {
      this.$API.Get('actionBar/queryActionBar').then(res => {
        this.tabData = res.data
        if(this.tabData != null && this.tabData.length > 0){
          this.backImageList = this.tabData[this.activeIndex].imageList
          this.activeItem = this.tabData[this.activeIndex];
        }
      })
    }
  }
}
</script>
<style scoped>
.header-title {
  width: 100%;
}
.content {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
.image-header {
  height: 100%;
  width: 100%;
}
.title-image {
  width: 16%;
  height: 100%;
  margin-left: 18.75%;
}
.header-tab {
  width: 84%;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  margin-right: 8.4%;
  line-height: 60px;
}
.tab:last-child {
  padding-right: 0;
}
.tab {
  display: block;
  font-size: 2.1vh;
  color: #fff;
  height: 100%;
  padding: 0 2%;
  cursor: pointer;
  font-family: SourceHanSansCN-Regular;
  letter-spacing: 5px;
}
.tab:hover {
  color: #00E9FF;
}
.active {
  color: #00E9FF;
  position: relative;
  font-size: 2.5vh;
}
.active::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  border-bottom: 3px solid #00E9FF;
}
  /deep/ .el-carousel__button{
    width: 110px;
    height: 3px;
  }
  /deep/ .el-carousel__arrow{
    width: 33px;
    height: 61px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(31,45,61,0);
  }
  /deep/ .el-carousel__arrow--right{
    background-image: url("../assets/image/u16.png");
  }
  /deep/ .el-carousel__arrow--left{
    background-image: url("../assets/image/u15.png");
  }
  /deep/ .el-carousel__arrow i{
    display: none;
  }
</style>