<template>
  <div class="tab-dynamic">
    <div class="add-form">
      <div class="title" style="padding: 1% 1% 0 1%">添加轮播图</div>
      <div class="header-btn">
        <el-button type="primary" @click="addNewsInfo">添加新闻信息</el-button>
      </div>
      <el-dialog
        center
        title="添加标题信息"
        :visible.sync="tabDialogVisible"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <el-form
          ref="tabDataRef"
          class="new-form"
          :rules="tabDataRules"
          :model="tabDataForm"
          label-width="80px"
        >
          <el-form-item prop="title" label="标题名称">
            <el-input
              placeholder="请输入标题"
              v-model="tabDataForm.title"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleAddTab">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="add-image">
      <div v-for="item in tabList" :key="item.id">
        <div class="title">{{ item.title }}</div>
        <el-upload
                ref="upload"
                class="upload-demo"
                accept=".jpg,.png"
                list-type="picture-card"
                :action="action"
                :on-success="(response, file, fileList) => {
                  handleSuccess(response, file, fileList,item)
                }"
                :before-remove="(file, fileList) => {
                    handleRemove(file, fileList, item)
                }"
                multiple
                :file-list="item.imageList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddTabList",
  data() {
    return {
      tabDataForm: {
        path: "",
        title: "",
      },
      tabDialogVisible: false,
      action: "",
      fileList: [],
      tabList: [],
      tabDataRules: {
        path: [{ required: true, message: "请输入链接", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      pageNum: 1,
      pageSize: 13,
      total: 0,
      imageDialogVisible: false,
      isAdd: null,
    };
  },
  mounted() {
    this.getTabList();
    // this.getBannerImage();
    this.action =
      initConfig(process.env.NODE_ENV === "production").$Config.baseUrl +
      "information/upload";
  },
  methods: {
    addNewsInfo() {
      this.$router.push("/addNews");
    },
    handleRemove(file, fileList, item) {
        console.log(file)
      this.$API
        .Get("image/delete", {
          id: file.id ? file.id : file.uid,
        })
        .then((res) => {
          if (res.succ) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            return true;
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
            return false;
          }
        });
    },
    handleSuccess(response, file, fileList,item) {
      this.$API
        .Post("image/add", {
          id: file.uid,
          imagePath: response,
          barId: item.id
        })
        .then((res) => {
          if (res.succ) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "添加失败!",
            });
          }
        });
    },
    handleClose() {
      this.$refs.tabDataRef.resetFields();
      this.tabDialogVisible = false;
    },
    getTabList() {
      this.$API.Get("actionBar/queryActionBar").then((res) => {
        if (res.succ) {
          this.tabList = res.data;
        } else {
          this.$message({
            type: "error",
            message: "获取失败!",
          });
        }
      });
    },
    getBannerImage() {
      this.$API.Get("image/queryImage", {type: 1}).then((res) => {
        if (res.succ) {
          this.fileList = res.data.map((item) => ({
            id: item.id,
            url: item.imagePath,
          }));
        } else {
          this.$message({
            type: "error",
            message: "获取失败!",
          });
        }
      });
    },
    addTabInfo() {
      this.tabDataForm.id = "";
      this.isAdd = true;
      this.tabDialogVisible = true;
    },
    handleAddTab() {
      this.$refs.tabDataRef.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.$API.Post("actionBar/add", this.tabDataForm).then((res) => {
              if (res.succ) {
                this.getTabList();
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.$refs.tabDataRef.resetFields();
                this.tabDialogVisible = false;
              }
            });
          } else {
            this.$API.Post("actionBar/modify", this.tabDataForm).then((res) => {
              if (res.succ) {
                this.getTabList();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.$refs.tabDataRef.resetFields();
                this.tabDialogVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //限制用户上传的图片格式和大小
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG、PNG 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    handleEdit(index, row) {
      this.tabDataForm.id = row.id;
      this.isAdd = false;
      this.tabDialogVisible = true;
      this.tabDataForm.title = row.title;
      this.tabDataForm.path = row.path;
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$API
            .Get("actionBar/delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.succ) {
                this.getTabList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.tab-dynamic {
  width: 100%;
  height: 100%;
}
.add-form {
  width: 100%;
  height: 100%;
}
.add-image {
  padding: 1%;
}
.header-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0 1%;
}
.content {
  padding: 1%;
}
.new-form {
  margin-top: 20px;
}
.dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
</style>