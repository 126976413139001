<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <!--AI中台-->
      <div class="middleground-content">
        <div class="middleground-content-text">AI中台</div>
        <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
      </div>
      <div class="middleground-item-dev">
        <div class="middleground-item" v-for="item in aiMiddlegroundList" :key="item.id">
          <img :src="item.imagePath" alt="" />
          <div class="middleground-item-des-dev">
            <div class="middleground-item-title">{{ item.caption }}</div>
            <div class="middleground-item-description">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <shareCode></shareCode>
      <!--数据中台-->
      <div class=" middleground-content">
        <div class="middleground-content-text">数据中台</div>
        <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
      </div>
      <div class="middleground-item-dev">
        <div class="middleground-item" v-for="item in middlegroundList" :key="item.id" @click="selectMiddleGround('DataCapacity', 1)">
          <img :src="item.imagePath" alt="" />
          <div class="middleground-item-des-dev">
            <div class="middleground-item-title">{{ item.caption }}</div>
            <div class="middleground-item-description">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <footer-content></footer-content>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import shareCode from '../../components/share.vue';
  export default {
    name: 'Index',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        //数据中台列表
        aiMiddlegroundList:[],
        //数据中台列表
        middlegroundList:[],
      }
    },
    mounted() {
      this.queryAiMiddleground();
      this.queryMiddleground();
    },
    methods: {
      /**
       * 查询数据中台数据
       */
      queryMiddleground(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 2}).then((res) => {
          if (res.succ) {
            this.middlegroundList = res.data;
          }
        });
      },
      /**
       * 查询AI中台数据
       */
      queryAiMiddleground(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 0}).then((res) => {
          if (res.succ) {
            this.aiMiddlegroundList = res.data;
          }
        });
      },
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #F3F6F7;
  }
  .middleground-content{
    height: 110px;
    margin-top: 70px;
    text-align: center;
  }
  .middleground-content-text{
    font-size: 26px;
  }
  .middleground-item-dev{
    width: 70%;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
  }
  .middleground-item {
    width: 48%;
    margin: 0% 1% 1.7% 1%;
    transform: translateY(0px);
  }
  .middleground-item:hover{
    transform: scale(1.02, 1.02);
  }
  .middleground-item img{
    width: 100%;
    position: relative;
  }
  .middleground-item-des-dev{
    padding: 20px;
    position: absolute;
    top: 30%;
  }
  .middleground-item-title{
    font-size: 26px;
    font-weight: 600;
    margin: 20px 0px;
    text-align: center;
  }
  .middleground-item-description{
    font-size: 1.6vh;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 2px;
    color: #767676;
  }
</style>