<template>
  <div class="news-dynamic">
    <div class="news">
      <div class="news-content">
        <div class="news-item" v-for="item in listData" :key="item.id">
          <a :href="item.articlePath" target="_blank">
            <img :src="item.imagePath" alt="" />
            <div class="info">
              <div class="time-info">
                {{ item.author }}
                <span>|</span>
                {{ item.publishedTime }}
              </div>
              <div class="title">
                {{ item.title }}
              </div>
              <div class="content-info">
                {{ item.content }}
              </div>
              <span class="more">了解更多></span>
            </div>
          </a>
        </div>
      </div>
    
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          @prev-click="handlePrevClick"
          @next-click="handleNextClick"
          layout="total, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <shareCode></shareCode>
  </div>
</template>
<script>
import HeaderTitle from "../../components/HeaderTitle.vue";
import shareCode from '../../components/share.vue';
export default {
  name: "NewsDynamic",
  components: {
    HeaderTitle,
    shareCode
  },
  data() {
    return {
      listData: [],
      pageNum: 1,
      pageSize: 6,
      total: 0,
    };
  },
  mounted() {
    this.getListInfo();
  },
  methods: {
    getListInfo() {
      this.$API
        .Get("information/queryInformation", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.listData = res.data.records;
          this.total = res.data.total;
          console.log("ssss", res);
        });
    },
    handlePrevClick(pageNum) {
      this.pageNum = pageNum;
      this.getListInfo();
    },
    handleNextClick(pageNum) {
      this.pageNum = pageNum;
      this.getListInfo();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getListInfo();
    },
  },
};
</script>
<style scoped>
.news-dynamic {
  width: 100%;
}
.image-header {
  height: 100%;
  width: 100%;
}
.news {
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 6.5%;
  padding-bottom: 5.4%;
}
.news-content {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.news-item {
  width: 28.8%;
  cursor: pointer;
  margin: 0 1% 6.7%;
  transform: translateY(0px);
}
.news-item:nth-child(3n + 1) {
  margin-left: 6%;
}
.news-item img {
  display: flex;
  width: 100%;
  height: 22vh;
}
.info {
  padding: 2.5% 8.5%;
}
.time-info {
  padding-left: 3px;
  width: 100%;
  text-align: left;
  color: #828282;
  font-size: 1.65vh;
  letter-spacing: 2px;
  margin-bottom: 7.2%;
  font-family: MicrosoftYaHei;
}
.time-info > span {
  padding: 0 10px;
}
.title {
  font-size: 1.85vh;
  font-weight: bold;
  font-stretch: normal;
  height: 5.7vh;
  line-height: 3.1vh;
  letter-spacing: 2px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 7.4%;
}
.content-info {
  /* height: 39px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing: 1px;
  font-size: 1.45vh;
  height: 5.4vh;
  line-height: 2.7vh;
  margin-bottom: 4%;
  color: #2c3e50;
}
.more {
  display: block;
  text-align: right;
  color: #979797;
  font-size: 14px;
  line-height: 3.7vh;
  font-weight: bold;
}
.news-item:hover {
  /* transform: translateY(-5px);
  animation: all 1s infinite ; */
  box-shadow: 0 2px 12px 0 #666;
  background-color: #fff;
  transform: scale(1.02, 1.02);
}
.news-item:hover .title,
.news-item:hover .more {
  color: #1161b3;
}

.pagination {
  display: flex;
  justify-content: center;
}
/deep/ .el-pagination button:disabled,
/deep/ .el-pager,
/deep/ .el-pager li,
/deep/ .el-pagination .btn-next,
/deep/ .el-pagination .btn-prev {
  background-color: transparent !important;
}
/deep/ .el-pagination__total,
/deep/ .number {
  font-size: 2.3vh !important;
  line-height: 28px;
  letter-spacing: 1px;
  color: #333333;
}
/deep/ .el-pagination .btn-next .el-icon,
/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 2.3vh !important;
}
/deep/ .el-pager li.active {
  line-height: 28px;
  letter-spacing: 1px;
  color: #0e57a1;
}
</style>