import http from './http'
import router from '../router'
import Utils from '../utils/index'
import store from '../store'

class BaseApi {
  constructor(url, params, method, headers, config, vue) {
    return new Promise((resolve, reject) => {
      let newHeaders = new Api(config)
      let content = newHeaders.getToken()
      http({
        //这里是你自己的请求方式、url和data参数
        method: method,
        url:config.baseUrl + url,
        data: params,
        //假设后台需要的是表单数据这里你就可以更改
        headers: content,
      })
        .then(function(res) {
          if(res && res.headers && res.headers.token){
            newHeaders.setToken(res.headers.token)
            headers["token"] = res.headers.token
          }
          let data = res.data
          if (data.code === 500 && data.msg) {
            new Utils().message('error',data.msg)
            reject(data)
          } else {
            resolve(data)
          }
        }).catch(function(err) {
          if(err.response && err.response.status == 401){
            new Utils().message('error','登录超时，请重新登录')
            router.push('/');
          }else{
            reject(err)
          }
        })
    })
  }
}

// new 的时候需要指定 Config
class Api {
  // 配置类
  config
  // vue的this 对象私有方法 私有方法必须有 Babel编译器进行编译才能使用，没有此环境不要用
  #vue

  constructor(config) {
    this.config = config
    this.initHeaders()
  }

  initVue(vue) {
    this.#vue = vue
  }

  initHeaders() {
    let userId = store.state.userInfo ? JSON.parse(store.state.userInfo).id : ''
    let headers = localStorage.getItem(this.config.name + '-Headers.' + userId)
    let data = JSON.parse(headers)
    if (headers && data) this.AddHeaders(data)
  }

  getToken(){
    let userId = store.state.userInfo ? JSON.parse(store.state.userInfo).id : ''
    let headers = localStorage.getItem(this.config.name + '-Headers.' + userId)
    let data = JSON.parse(headers)
    return data
  }

  setToken(token){
    let userId = store.state.userInfo ? JSON.parse(store.state.userInfo).id : ''
    let headers = localStorage.getItem(this.config.name + '-Headers.' + userId)
    let data = JSON.parse(headers)
    data.token = token;
    localStorage.setItem(this.config.name + '-Headers.' + userId, JSON.stringify(data))
    this.headers["token"] = token
  }

  headers = {
    'Content-Type': 'application/json;charset=UTF-8',
  }

  Get(url, params) {
    return new BaseApi(url, params, 'get', this.headers, this.config, this.#vue)
  }

  Post(url, params) {
    return new BaseApi(url, params, 'post', this.headers, this.config, this.#vue)
  }

  Put(url, params) {
    return new BaseApi(url, params, 'put', this.headers, this.config, this.#vue)
  }

  Delete(url, params) {
    return new BaseApi(url, params, 'delete', this.headers, this.config, this.#vue)
  }

  BaseApi(url, params, method, headers) {
    return new BaseApi(url, params, method, headers, this.config, this.#vue)
  }

  AddHeaders(obj) {
    let userId = store.state.userInfo ? JSON.parse(store.state.userInfo).id : ''
    if (!obj) return
    Object.keys(obj).forEach(item => {
      this.headers[item] = obj[item]
    })
    localStorage.setItem(this.config.name + '-Headers.' + userId, JSON.stringify(obj))
  }
}

export default Api
