<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <!--tab-->
      <el-tabs v-model="index" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabData" :label="item.title" :name="index + ''">
        </el-tab-pane>
      </el-tabs>
      <!--公司简介-->
      <div id="company-info" style="width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
}">
        <img :src="companyDes.imagePath" style="width: 50%"/>
        <div>
          <div style="font-size: 3.6vh;padding: 10px 30px">汇智星源</div>
          <div style="padding: 0px 30px;font-size: 1.9vh;line-height: 40px;text-indent: 30px;">{{ companyDes.description }}</div>
        </div>
      </div>
      <!--企业文化-->
      <div id="company-culture">
        <img src="../../assets/image/jiazhiguan.png" />
      </div>
      <!--发展历程-->
      <div id="development-history">
        <div class="middleground-content">
          <div class="middleground-content-text">发展历程</div>
          <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
        </div>
        <div class="development-dev">
          <div class="development-carousel">
            <el-carousel :interval="5000" height="72vh" arrow="never">
              <el-carousel-item v-for="(item, index) in dataList" :key="index">
                <!--年度名称-->
                <div class="development-caption">{{ item.caption }}</div>
                <!--发展历程图片-->
                <div class="development-img-dev">
                  <img class="development-img" v-for="img in item.historyList" :src="img.imagePath" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <shareCode></shareCode>
      <!--荣誉资质-->
      <div id="honor">
        <div class="middleground-content">
          <div class="middleground-content-text">荣誉资质</div>
          <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
        </div>
        <div class="honor-dev">
          <!-- <div style="font-size: 2.2vh;padding: 0px 100px;line-height: 35px;text-indent: 30px;">20项+社会各项荣誉证书：国家高新技术企业、  国家科技型中小企业、  双软企业、天津市“专精特新”企业、  天津市瞪羚企业、  第十届中国创新创业大赛天津赛区一等奖、第七届创客中国天津赛区二等奖、 “创赢天下”第三届哈工大全球校友创新创业大赛二等奖、第六届清华校友三创大赛总决赛AI大数据十强奖、与司法部《法治日报》成立AI+法律科技创新联合实验室</div> -->

          <!--<el-carousel :interval="5000" :height="bannerHeight">
            <el-carousel-item v-for="(item, index) in honorDataList" :key="index">
              <div class="middleground-item-dev">
                <div class="middleground-item" v-for="img in item.historyList">
                  <div class="middleground-item-img-dev">
                    <img :src="img.imagePath" alt="" />
                  </div>
                  <div class="honor-description">{{ img.description }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>-->
          <div class="middleground-item-dev">
            <div class="middleground-item" v-for="img in honorDataList">
              <div class="middleground-item-img-dev">
                <img :src="img.imagePath" alt="" />
              </div>
              <div class="honor-description">{{ img.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--<div>
        <img src="../../assets/image/u421.png" style="width: 100%">
      </div>-->
      <!--联系我们-->
      <div id="call-us">
        <footer-content></footer-content>
      </div>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import shareCode from '../../components/share.vue';
  export default {
    name: 'AboutUs',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        tabData: [],
        dataList: [],
        honorDataList: [],
        index: 0,
        companyDes: {
          imagePath: "",
          description: ""
        },
        bannerHeight: "110vh"
      }
    },
    mounted() {
      this.getCompanyDes();
      this.getTabInfo();
      this.queryDevelopmentHistory();
      this.queryHonorDataList();
      this.reloadImg(document.body.clientWidth)
      window.addEventListener('resize',() => {
        window.screenWidth = document.body.clientWidth
        console.info(document.body.clientWidth);
        console.info(window.screenWidth)
        this.reloadImg()
      },false)
    },
    methods: {
      reloadImg(screenWidth){
        if(screenWidth >= 1920){
          this.bannerHeight = "72vh"
        }else if(screenWidth >= 1440 && screenWidth < 1920){
          this.bannerHeight = "90vh"
        }else{
          this.bannerHeight = "110vh"
        }
      },
      /**
       * 查询公司简介
       */
      getCompanyDes(){
        this.$API.Get("tab-middleground/queryMiddleground", {type: 11}).then((res) => {
          if (res.succ && res.data && res.data.length > 0) {
            this.companyDes = res.data[0];
          }
        });
      },
      /**
       * 查询二级菜单信息
       */
      getTabInfo() {
        this.$API.Get('actionBar/queryActionBar', {parentId: "7"}).then(res => {
          this.tabData = res.data
        })
      },
      /**
       * 查询发展历程列表
       */
      queryDevelopmentHistory(){
        this.$API.Get('tab-development-history/queryDevelopmentHistory', {type: 0}).then(res => {
          this.dataList = res.data
        })
      },
      /**
       * 查询发展历程列表
       */
      queryHonorDataList(){
        this.$API.Get('tab-development-history/queryHonorList', {type: 1}).then(res => {
          this.honorDataList = res.data
        })
      },
      /**
       * 点击tab事件
       */
      handleClick(tab, event){
        switch (tab.index) {
          case "0":
            //公司简介
            document.querySelector("#company-info").scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
            break;
          case "1":
            //企业文化
            document.querySelector("#company-culture").scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
            break;
          case "2":
            //发展历程
            document.querySelector("#development-history").scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
            break;
          case "3":
            //荣誉资质
            document.querySelector("#honor").scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
            break;
          case "4":
            //联系我们
            document.querySelector("#call-us").scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
            break;
        }
      },
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #FAFAFC;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }
  /deep/ .el-tabs__header{
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: center;
  }
  /deep/ .el-tabs__nav-wrap::after{
    height: 0px;
  }
  .middleground-content{
    height: 50px;
    margin-top: 70px;
    text-align: center;
  }
  /deep/ .el-tabs__nav-scroll{
    height: 100%;
  }
  /deep/ .el-tabs__nav{
    height: 100%;
  }
  /deep/ .el-tabs__item{
    height: 85px;
    width: 120px;
    color: black;
    font-size: 18px;
    line-height: 85px;
    text-align: center;
    padding: 0px;
  }
  /deep/ .el-tabs__item:hover{
    background-color: #465B9C;
    color: white;
  }
  /deep/ .el-tabs__item.is-active{
    color: black;
    background-color: white;
  }
  /deep/ .el-tabs__active-bar{
    width: 70px !important;
    margin-left: 25px;
    transform: translateX(120px);
    height: 3px;
    background-color: #465B9C;
  }
  .middleground-content-text{
    font-size: 35px;
  }
  #company-info{
    width: 100%;
  }
  #company-info img{
    width: 100%;
  }
  #company-culture{
    width: 100%;
  }
  #company-culture img{
    width: 100%;
  }
  #development-history{
    background-color: white;
  }
  .development-carousel{
    width: 80%;
    margin-left: 10%;
    background-color: white;
  }
  .development-dev{
    text-align: center;
    background-color: white;
  }
  #development-history /deep/ .el-carousel__arrow{
    top: 40%;
    width: 55px;
    height: 45px;
    border-radius: 0px;
    background-color: white;
    transform: none;
   }
  #development-history /deep/ .el-carousel__arrow:hover{
    background-color: white;
  }
  #development-history /deep/ .el-carousel__arrow i{
    display: none;
  }
  #development-history /deep/ .el-carousel__arrow--left{
    background-image: url("../../assets/image/u427.png");
    background-repeat: no-repeat;
  }
  #development-history /deep/ .el-carousel__arrow--right{
    background-image: url("../../assets/image/u425.png");
    background-repeat: no-repeat;
  }
  #honor /deep/ .el-carousel__arrow{
    top: 40%;
    width: 55px;
    height: 45px;
    border-radius: 0px;
    background-color: #FAFAFC;
    transform: none;
  }
  #honor /deep/ .el-carousel__arrow:hover{
    background-color: #FAFAFC;
  }
  #honor /deep/ .el-carousel__arrow i{
    display: none;
  }
  #honor /deep/ .el-carousel__arrow--left{
    background-image: url("../../assets/image/u427.png");
    background-repeat: no-repeat;
    left: 0px;
  }
  #honor /deep/ .el-carousel__arrow--right{
    background-image: url("../../assets/image/u425.png");
    background-repeat: no-repeat;
    right: 0px;
  }
  .development-caption{
    font-size: 30px;
    margin: 50px 0px;
  }
  .development-img-dev{
    height: 75%;
    /* background-image: url("../../assets/image/u430.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .development-img{
    width: 100%;
    /* margin: 0px 4%; */
  }
  .middleground-item-dev{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .middleground-item {
    width: 24%;
    cursor: pointer;
    margin: 0% 1% 1% 1%;
    padding: 20px;
    transform: translateY(0px);
    background-color: white;
  }
  .middleground-item-img-dev{
    height: 240px;
    overflow: hidden;
  }
  .middleground-item img{
    width: 100%;
    height: 100%;
  }
  .honor-dev{
    width: 70%;
    margin-left: 15%;
    margin-top: 50px;
    margin-bottom: 70px;
  }
  .honor-description{
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }
</style>