<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <!--tab-->
      <div id="product-top"></div>
      <el-tabs v-model="index" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in tabData" :label="item.title" :name="index + ''">
          </el-tab-pane>
          <div class="middleground-content" v-show="!showDetailPage">
            <div class="middleground-content-text">公司产品</div>
            <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
          </div>
          <!--产品列表-->
          <div class="middleground-item-dev" v-show="!showDetailPage">
            <div class="middleground-item" v-for="(item, index) in dataList" :key="index" @click="selectProduct(item)">
              <img :src="item.imagePath" alt="" />
              <div style="padding: 20px;line-height: 40px">
                <div style="font-size: 18px; font-weight: 600">{{ item.caption }}</div>
                <div style="font-size: 14px;font-weight:600;color: #5E5E5E;letter-spacing: 2.3px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ item.description1 }}</div>
                <div style="color: #0067D0;font-size: 14px;font-weight:600;text-align: right;">查看更多>></div>
              </div>
            </div>
          </div>
          <shareCode></shareCode>
          <!--产品详情-->
          <div v-if="showDetailPage" :id="'product' + selectItem.id" class="product-detail">
            <!--面包屑-->
            <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 16px">
              <el-breadcrumb-item><a @click="showDetailPage = false">核心产品</a></el-breadcrumb-item>
              <el-breadcrumb-item>{{ selectItem.caption }}</el-breadcrumb-item>
            </el-breadcrumb>
            <!--产品功能-->
            <div class="middleground-content">
              <div class="middleground-content-text">产品功能</div>
              <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
            </div>
            <div class="fun-dev">
              <div v-for="(item, index) in productDetailList" class="fun-dev-item">
                <img :src="item.functionImg"/>
                <div class="fun-name">{{ item.productFunction }}</div>
              </div>
            </div>
            <!--应用场景-->
            <div class="middleground-content">
              <div class="middleground-content-text">场景及成效</div>
              <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
            </div>
            <div class="application-dev" :style="backgroundImage">
              <span class="application-description">{{ selectItem.description }}</span>
            </div>
            <!--产品成效-->
            <!--<div class="middleground-content">
              <div class="middleground-content-text">产品成效</div>
              <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
            </div>
            <div class="effect-dev">
              {{ selectItem.effect }}
            </div>-->
          </div>
      </el-tabs>
      <footer-content></footer-content>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import shareCode from '../../components/share.vue';
  export default {
    name: 'CoreProduct',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        tabData: [],
        dataList: [],
        showDetailPage: false,
        index: "0",
        selectItem: {
          caption: ""
        },
        productDetailList: []
      }
    },
    mounted() {
        if(sessionStorage.getItem("secondMenuIndex")){
            this.index = sessionStorage.getItem("secondMenuIndex");
        }
        this.getTabInfo();
        this.queryMiddleground(this.index);
    },
    methods: {
      /**
       * 查询二级菜单信息
       */
      getTabInfo() {
        this.$API.Get('actionBar/queryActionBar', {parentId: "3"}).then(res => {
          this.tabData = res.data
        })
      },
      /**
       * 点击tab事件
       */
      handleClick(tab, event){
        this.showDetailPage = false;
        sessionStorage.removeItem("secondMenuIndex")
        this.queryMiddleground(tab.index);
      },
      queryMiddleground(index){
          let type = 4;
          switch (index) {
              case "0":
                  //机器换人
                  type = 4;
                  break;
              case "1":
                  //辅助业务产品
                  type = 5;
                  break;
              case "2":
                  //辅助决策产品
                  type = 6;
                  break;
          }
        this.$API.Get("tab-middleground/queryMiddleground", {type: type}).then((res) => {
          if (res.succ) {
            this.dataList = res.data;
          }
        });
      },
      selectProduct(item){
        this.selectItem = item;
          this.backgroundImage = { backgroundImage: 'url(' + item.applicationScene + ')' }
        this.showDetailPage = true;
        this.queryProductDetail(item.id);
      },
      queryProductDetail(productId){
        this.$API.Get("tab-product-detail/queryProductDetail", {solutionId: productId}).then((res) => {
          if (res.succ) {
            this.productDetailList = res.data;
            console.log("跳转" , this.showDetailPage)
            if(this.showDetailPage){
              document.querySelector("#product-top").scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              })
            }
          }
        });
      }
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #F3F6F7;
  }
  /deep/ .el-tabs__header{
    width: 100%;
    height: 85px;
    background-color: #2B3D78;
    display: flex;
    justify-content: center;
   }
  /deep/ .el-tabs__nav-wrap::after{
    height: 0px;
  }
  .middleground-content{
    height: 110px;
    margin-top: 20px;
    text-align: center;
  }
  /deep/ .el-tabs__nav-scroll{
    height: 100%;
  }
  /deep/ .el-tabs__nav{
    height: 100%;
  }
  /deep/ .el-tabs__item{
    height: 85px;
    width: 200px;
    line-height: 85px;
    margin: 0px 20px;
    color: #C7C7C7;
    font-size: 26px;
    text-align: center;
  }
  /deep/ .el-tabs__item.is-active{
    background-color: #465B9C;
    color: white;
  }
  /deep/ .el-tabs__active-bar{
    display: none;
  }
  .middleground-content-text{
    font-size: 35px;
      margin-top: 70px;
  }
  .middleground-item-dev{
    width: 70%;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
  }
  .middleground-item {
    width: 30%;
    cursor: pointer;
    margin: 0% 1.5% 1.7% 1.5%;
    transform: translateY(0px);
    background-color: white;
  }
  .middleground-item:hover{
    transform: scale(1.02, 1.02);
  }
  .middleground-item img{
    width: 100%;
  }
  .product-detail{
    width: 70%;
    margin-left: 15%;
    margin-top: 50px;
    padding: 20px;
  }
  .fun-dev{
    display: flex;
    flex-wrap: wrap;
  }
  .fun-dev-item{
    width: 22%;
    margin: 1%;
    display: flex;
    align-items: center;
    font-size: 22px;
    border: 1px solid #DEDEDE;
    background-color: white;
    padding: 20px 0px;
    justify-content: center;
  }
  .fun-dev-item img{
    margin-right: 40px;
  }
  .application-dev{
    display: flex;
    margin-bottom: 70px;
    height: 300px;
    justify-content: center;
    background-image: url("http://47.94.246.198:18080/cispirit_img/product/数智辅助决策分析平台/数智辅助.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .application-dev img{
    position: absolute;
    width: 70%;
  }
  .application-description{
    width: 80%;
    position: relative;
    top: 100px;
    font-size: 2.5vh;
    color: #FFF;
  }
  @media screen and (max-width: 1366px) {
    /*当屏幕尺寸大于1920时，应用下面的CSS样式*/
    .application-description {
      width: 80%;
      position: relative;
      top: 120px;
      font-size: 2.5vh;
      color: #FFF;
    }
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    /*当屏幕尺寸大于1920时，应用下面的CSS样式*/
    .application-description {
      width: 80%;
      position: relative;
      top: 160px;
      font-size: 2.5vh;
      color: #FFF;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    /*当屏幕尺寸大于1920时，应用下面的CSS样式*/
    .application-description {
      width: 80%;
      position: relative;
      top: 161px;
      font-size: 2.5vh;
      color: #FFF;
    }
  }
  @media screen and (min-width: 1920px) {
    /*当屏幕尺寸大于1920时，应用下面的CSS样式*/
    .application-description {
      width: 80%;
      position: relative;
      top: 180px;
      font-size: 2.5vh;
      color: #FFF;
    }
  }
  .effect-dev{
    height: 100px;
    background-color: #EEEEEE;
    color: black;
    padding: 30px 20px;
    font-size: 2vh;
    line-height: 35px;
  }
</style>