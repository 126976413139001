import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css';

import './assets/css/reset.css';
import Api from './api'
import 'babel-polyfill'
import "@/assets/iconfont/iconfont.css";
import vuescroll from 'vuescroll';
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import '../node_modules/swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.use(ElementUI)
Vue.use(ElementUI, { locale })

//滚动条全局配置
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: "native",  //模式:pc natice 移动端是slice
      sizeStrategy: "percent",  //父元素是否是固定的是就percent 不是就是number 填一个数值
      detectResize: true,  //内容是否根据页面调整
    },
    scrollPanel: {
      initialScrollY: false,  //初始化距离顶部的位置
      initialScrollX: false,  //初始化距离左侧的位置
      scrollingX: true,  // 是否开启横向滚动
      scrollingY: true,  //是否开启竖向滚动
      speed: 300,       //多长时间内完成一次滚动。 数值越小滚动的速度越快。
      easing: 'easeInQuad', //默认动画
      verticalNativeBarPos: "right", 
      maxHeight: undefined,  //这是滚动条最大高度,内容高度小于 maxHeight 时高度自适应，超出的话出现滚动条。
      maxWidth: undefined,   //这是滚动条最大宽度,内容宽度小于 maxWidth 时高度自适应，超出的话出现滚动条。
    },
    rail: {
      background: "#000000", //轨道的背景色。
      opacity: 0,            //轨道的透明度。 0是透明，1是不透明
      size: "6px",             //轨道的尺寸。
      specifyBorderRadius: false, //是否指定轨道的 borderRadius， 如果不那么将会自动设置。
      gutterOfEnds: null,
      gutterOfSide: "0px",      //距离容器的距离
      keepShow: false,          //是否即使 bar 不存在的情况下也保持显示。
    },
    bar: {
      showDelay: 500,             //在鼠标离开容器后多长时间隐藏滚动条。
      onlyShowBarOnScroll: true,  //是否只在滚动时显示 bar。
      keepShow: false,            //滚动条是否保持显示。
      background: "#0E57A2",      //滚动条背景色。
      opacity: 1,                 //滚动条透明度。
      hoverStyle: false,
      specifyBorderRadius: false,  //是否指定滚动条的 borderRadius， 如果不那么和轨道的保持一致。
      minSize: false,              //为 bar 设置一个最小尺寸, 从 0 到 1. 如 0.3, 代表 30%.
      size: "6px",                 //bar 的尺寸。
      disable: false,              //是否禁用滚动条。
    },
  },
});

const config = initConfig(process.env.NODE_ENV === 'production')

Vue.prototype.$API = new Api(config.$Config)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
