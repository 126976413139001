import Vue from 'vue'
import VueRouter from 'vue-router'
import NewsDynamic from '../views/newsDynamic/NewsDynamic.vue'
import MainContent from '../views/newsDynamic/MainContent.vue';
import AddNewsList from '../views/addNewsList/AddNewsList.vue';
import AddTabList from '../views/addTabList/AddTabList.vue';
import Login from '../views/login/Login.vue';
import Index from '../views/index/index';
import DataCapacity from '../views/dataCapacity/dataCapacity';
import CoreProduct from '../views/coreProduct/coreProduct'
import Solution from '../views/solution/solution'
import AboutUs from '../views/aboutUs/aboutUs'
import JoinUs from '../views/joinUs/joinUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
  },
  {
    path: '/newsDynamic',
    name: 'MainContent',
    component: MainContent,
  },
  {
    path: '/addNews',
    name: 'AddNewsList',
    component: AddNewsList
  },
  {
    path: '/addTab',
    name: 'AddTabList',
    component: AddTabList
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dataCapacity',
    name: 'DataCapacity',
    component: DataCapacity
  },
  {
    path: '/coreProduct',
    name: 'CoreProduct',
    component: CoreProduct
  },
  {
    path: '/solution',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: JoinUs
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/addNews' || to.path === '/addTab') {
    const tokenStr = sessionStorage.getItem('isLogin')
    console.log('---->', tokenStr)
    if (tokenStr === 'false' || tokenStr === null) {
      return next('/login')
    }
  }

  next()
})

export default router

