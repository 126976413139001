<template>
  <div class="share">
    <div class="share-title text-center">分享</div>
    <div class="social-share share-component" data-initialized="true">
      <div class="share-box"> 
        <span class="social-share-icon iconfont iconweixin"></span>
        <div class="popupCode">
          <div style="width:200px;height:190px;text-align:center"><img style="width:100%;height:100%" src="@/assets/image/code.jpg" alt=""></div>
          <div class="codeTitle">扫描二维码，关注我的公众号</div>
        </div>
      </div> 
      <div class="share-box share-icon"> 
        <span class="social-share-icon iconfont iconshipinhao"></span>
        <div class="popupVideo">
          <div style="width:200px;height:190px;text-align:center"><img style="width:100%;height:100%" src="@/assets/image/videid.jpg" alt=""></div>
          <div class="codeTitle">扫描二维码，关注我的视频号</div>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listImg:[1,2]
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style scoped>
.share {
    position: fixed;
    right: 0;
    top: calc(75% - 87px);
    background: #ffffff;
    z-index: 11;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding:0 0 20px 0;
}
.share-title{
    font-size: 14px;
    color: #999;
    padding: 0.521vw 0 0.521vw 0;
}
.text-center{
  text-align: center;
}
.social-share{
  font-family: "socialshare" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
   
}
.share-box {
    cursor: pointer;
    height: 66px;
    width: 58px;
    text-align: center;
    line-height: 66px;
    color: #ccc;
    padding: 3px 0; 
}
.share-box:hover .social-share-icon {
    color: #0A4391 !important;
    font-size:1.9vw;
    margin-left:-0.208vw;
}
.share-box:hover {
    color: #0A4391;
}

.social-share-icon{
  position: relative;
  display: inline-block;
  font-size: 1.567vw;
  width:1.567vw;
  height:1.567vw;
  margin:0.208vw;
  color: #0A4391;
}
.share-box:hover .popupCode{
  display: block;
}
.popupCode{
  position: absolute;
  top: -80px;
  right: 60px;
  width: 200px;
  height: 210px;
  background-color: white;
  display: none;
}
.share-box:hover .popupVideo{
  display: block;
}
.popupVideo{
  position: absolute;
  top: 50px;
  right: 60px;
  width: 200px;
  height: 210px;
  background-color: white;
  display: none;
}
.codeTitle{
  color:#C9C9C9;
  font-size: 10px;
  text-align: center;
  margin-top:-25px;
}

</style>