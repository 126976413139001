<template>
  <div class="footer">
    <div class="footer-content">
      <div class="relation">
        <div class="top-content">
          <div class="text">联系我们</div>
          <div class="content-active">Contact Us</div>
        </div>
      </div>
      <div class="site">
        <div class="company-name">
          <div class="icon"></div>
          <div class="name">天津汇智星源信息技术有限公司</div>
        </div>
        <div class="site-content">
          <span class="site-icon"></span>
          天津市滨海高新区华苑产业区开华道22号西塔20层
        </div>
        <div class="site-content">
          <span class="site-icon icon-active"></span>
          022-83726110
        </div>
      </div>
      <div class="code">
        <div class="code-image"></div>
      </div>
      <div class="footer-name">
        company
        <span class="copyright"></span>
        天津汇智星源信息技术有限公司
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterContent",
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer {
  width: 100%;
  height: 46.3vh;
  background-image: url("../assets/image/footer.png");
  background-size: 100% 100%;
}
.footer-content {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  padding-top: 3.2%;
  box-sizing: border-box;
  position: relative;
}
.relation {
  text-align: center;
  width: 35%;
  height: 100%;
  margin-right: 8%;
  font-size: 20px;
}
.top-content {
  font-size: 4.1vh;
  color: #fff;
  padding-left: 5%;
  font-family: MicrosoftYaHei;
  letter-spacing: 0px;
}
.content-active {
  color: #ffffff;
  opacity: 0.42;
  margin-top: 5.4%;
  font-size: 3.3vh;
  transform: translateX(2%);
}
.site {
  margin-right: 3%;
  width: 60%;
  height: 100%;
}
.company-name {
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center;
}

.icon {
  width: 10.5%;
  height: 84%;
  background-image: url("../assets/image/baise.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-left: 11px;
}
.name {
  font-size: 1.65vh;
  color: #fff;
  font-weight: bold;
  letter-spacing: 3px;
}
.site-content {
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 15%;
  margin-bottom: 4%;
  margin-top: 2%;
  font-weight: bold;
  font-size: 1.45vh;
}
.site-icon {
  display: inline-block;
  height: 1.8vh;
  width: 2vh;
  background-image: url("../assets/image/site.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}
.icon-active {
  background-image: url("../assets/image/tel.png");
}
.code {
  width: 40%;
  height: 100%;
}
.code-image {
  width: 15vh;
  height: 15vh;
  /*background-image: url("../assets/image/code.jpg");
  background-repeat: no-repeat;
  background-size: contain;*/
  margin-left: 13%;
}
.footer-name {
  position: absolute;
  left: 50%;
  bottom: 4vh;
  transform: translateX(-50%);
  font-family: MicrosoftYaHei;
  font-size: 1.45vh;
  font-weight: normal;
  font-stretch: normal;
  /* line-height: 31px; */
  letter-spacing: 0px;
  color: #ffffff;
}
.copyright {
  display: inline-block;
  width: 1.4vh;
  height: 1.4vh;
  vertical-align: middle;
  background-image: url('../assets/image/copyright.png');
  background-size: 100% 100%;
}
</style>