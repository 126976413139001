<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <div>
        <!--方案列表-->
        <div class="middleground-item" v-if="!showDetailPage" v-for="item in dataList" :key="item.id" @click="selectSolution(item)">
          <img class="description-img" :src="item.imagePath" alt="" />
          <div class="description-dev">
            <div class="description-title">
              {{ item.caption }}
            </div>
            <div class="description-detail">
              {{ item.description }}
            </div>
            <div class="more">
              <span>查看更多>></span>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
        <!--方案详情-->
        <div v-if="showDetailPage" class="solution-detail" id="solution-detail">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 16px">
            <el-breadcrumb-item><a @click="showDetailPage = false">解决方案</a></el-breadcrumb-item>
            <el-breadcrumb-item>{{ selectItem.caption }}</el-breadcrumb-item>
          </el-breadcrumb>
          <!--标题-->
          <div class="middleground-content">
            <div class="middleground-content-text">{{ selectItem.caption }}</div>
            <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
          </div>
          <!--简介-->
          <div class="solution-detail-description">
            {{ selectItem.description }}
          </div>
          <!--功能特色-->
          <div class="middleground-content">
            <div class="middleground-content-text">功能特色</div>
            <img src="../../assets/image/u85.png" style="width: 230px;height: 5px" alt="" />
          </div>
          <!--特色列表-->
          <div class="solution-fun">
            <div v-for="(item, index) in funDataList" class="solution-fun-item">
              <img :src="item.featureImg"/>
              <div>{{ item.feature }}</div>
            </div>
          </div>
        </div>
      </div>
      <footer-content></footer-content>
    
    </vue-scroll>
    <shareCode></shareCode>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import shareCode from '../../components/share.vue';
  export default {
    name: 'Index',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        dataList: [],
        showDetailPage: false,
        selectItem: {
          caption: ""
        },
        //功能特色列表
        funDataList: []
      }
    },
    mounted() {
      this.queryMiddleground(7);
    },
    methods: {
      queryMiddleground(type){
        this.$API.Get("tab-middleground/queryMiddleground", {type: type}).then((res) => {
          if (res.succ) {
            this.dataList = res.data;
          }
        });
      },
      selectSolution(item){
        this.selectItem = item;
        this.showDetailPage = true;
        this.querySolutionDetail(item.id);
      },
      querySolutionDetail(solutionId){
        this.$API.Get("tab-solution-detail/querySolutionDetail", {solutionId: solutionId}).then((res) => {
          if (res.succ) {
            this.funDataList = res.data;
            console.log("跳转" , this.showDetailPage)
            if(this.showDetailPage){
              document.querySelector("#solution-detail").scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              })
            }
          }
        });
      }
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #F3F6F7;
  }
  .middleground-item {
    width: 70%;
    cursor: pointer;
    margin: 2% 0% 2% 15%;
    transform: translateY(0px);
    padding: 40px 20px;
    background-color: white;
    display: flex;
    align-items: center;
  }
  .middleground-item:hover{
    transform: scale(1.02, 1.02);
  }
  .description-img{
    width: 40%;
    float: left;
  }
  .description-dev{
    width: 56%;
    float: left;
    padding: 0px 2%;
  }
  .description-title{
    font-size: 25px;
    font-weight: bold;
  }
  .description-detail{
    font-size: 14px;
    font-weight: bold;
    word-spacing: normal;
    margin-top: 40px;
    line-height: 25px;
    text-indent: 20px;
    letter-spacing: 7px;
  }
  .more{
    text-align: right;
    font-size: 14px;
    color: #027DB4;
    margin-top: 40px;
  }
  .solution-detail{
    width: 60%;
    margin-left: 20%;
    margin-top: 50px;
    padding: 20px;
  }
  .middleground-content{
    height: 110px;
    margin-top: 70px;
    text-align: center;
  }
  .middleground-content-text{
    font-size: 30px;
  }
  .solution-detail-description{
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
    line-height: 50px;
  }
  .solution-fun{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
  }
  .solution-fun-item{
    width: 43%;
    margin: 1%;
    display: flex;
    font-size: 18px;
    background-color: white;
    padding: 20px;
    line-height: 40px;
    font-weight: bold;
    min-height: 150px;
    align-items: center;
  }
  .solution-fun-item img{
    margin-right: 10px;
  }
</style>