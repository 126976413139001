<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <news-dynamic></news-dynamic>
      <footer-content></footer-content>
      <shareCode></shareCode>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import NewsDynamic from './NewsDynamic';
  import shareCode from '../../components/share.vue';
export default {
  name: 'MainContent',
  components: {
    HeaderTitle,
    NewsDynamic,
    FooterContent,
    shareCode
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style scoped>
.main-content {
  width: 100%;
  height: 100vh;
}
.image-header {
  height: 100%;
  width: 100%;
}
</style>