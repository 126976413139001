<template>
  <div class="main-content">
    <vue-scroll>
      <header-title></header-title>
      <!--福利介绍-->
      <div class="middleground-content">
        <div class="middleground-content-text">用AI让数据更智能 用爱让员工更幸福</div>
      </div>
      <div class="middleground-item-dev">
        <div class="middleground-item" v-for="item in dataList" :key="item.id" @click="selectMiddleGround('DataCapacity', 1)">
          <img :src="item.imagePath" alt="" />
          <div style="padding: 10px;line-height: 40px">
            <div style="font-size: 20px; font-weight: bold">{{ item.caption }}</div>
            <div style="font-size: 1.7vh;color: #818080;letter-spacing: 2.3px;">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <shareCode></shareCode>
      <!--招贤纳士-->
      <div class="middleground-content">
        <div class="middleground-content-text">招贤纳士</div>
      </div>
      <div class="job">
        <div class="job-dev">
          <!--tab-->
          <div class="job-title">
            <div class="job-title-item" :class="{itemActive:index==isActive}" v-for="(item, index) in jobList" :label="item.caption" @click="selectBtn(item, index)">
              {{ item.caption }}
            </div>
          </div>
          <div style="clear: both"></div>
          <el-card>
            <div style="font-size: 20px; font-weight: bold;margin-bottom: 20px;">{{ jobContent.caption }}({{ jobContent.num }}名)</div>
            <div class="job-content">{{ jobContent.education }}</div>
            <div class="job-content" style="font-weight: bold;">需求专业：</div>
            <div class="job-content">{{ jobContent.major }}</div>
            <div class="job-content" style="font-weight: bold;">岗位职责：</div>
            <div class="job-content" v-for="(str, index) in jobContent.dutyList">
              {{ str }}
            </div>
            <div class="job-content" style="font-weight: bold;">岗位要求：</div>
            <div class="job-content" v-for="(str, index) in jobContent.jobRequireList">
              {{ str }}
            </div>
            <div class="job-content" style="font-weight: bold;">联系方式：</div>
            <div class="job-content">
              1、邮箱：{{ jobContent.email }}
            </div>
            <div class="job-content">
              2、人事微信
              <img :src="jobContent.wechat" />
            </div>
          </el-card>
        </div>
      </div>
      <footer-content></footer-content>
    </vue-scroll>
  </div>
</template>
<script>
  import HeaderTitle from "../../components/HeaderTitle.vue";
  import FooterContent from '../../components/FooterContent.vue';
  import shareCode from '../../components/share.vue';
  export default {
    name: 'Index',
    components: {
      HeaderTitle,
      FooterContent,
      shareCode
    },
    data() {
      return {
        dataList:[],
        jobList: [],
        index: 0,
        isActive: 0,
        jobContent: {
          id: "",
          caption: "",
          num: 0,
          education: "",
          major: "",
          dutyList: [],
          jobRequireList: []
        }
      }
    },
    mounted() {
      this.queryMiddleground(8);
      this.queryJobList();
    },
    methods: {
      /**
       * 员工福利
       */
      queryMiddleground(type){
        this.$API.Get("tab-middleground/queryMiddleground", {type: type}).then((res) => {
          if (res.succ) {
            this.dataList = res.data;
          }
        });
      },
      /**
       * 招贤纳士
       */
      queryJobList(){
        this.$API.Get("tab-jobs/queryJobList").then((res) => {
          if (res.succ) {
            this.jobList = res.data;
            this.jobContent = this.jobList[0]
          }
        });
      },
      selectBtn(item, index){
        this.jobContent = item
        this.isActive = index
        console.log(this)
      }
    },
  }
</script>
<style scoped>
  .main-content {
    width: 100%;
    height: 100vh;
    background-color: #F3F6F7;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }
  .middleground-content{
    height: 110px;
    margin-top: 40px;
    text-align: center;
  }
  .middleground-content-text{
    font-size: 3.5vh;
    letter-spacing: 5px;
  }
  .middleground-item-dev{
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
  }
  .middleground-item {
    width: 20%;
    cursor: pointer;
    margin: 0% 0% 1.7% 5%;
    transform: translateY(0px);
  }
  .middleground-item:hover{
    transform: scale(1.02, 1.02);
  }
  .middleground-item img{
    width: 100%;
  }
  .job{
    display: flex;
    justify-content: center;
  }
  .job-dev{
    width: 70%;
    background-color: #FAFAFC;
    padding: 20px;
  }
  .job-title{
    background-color: #FAFAFC;
    display: flex;
    flex-wrap: wrap;
  }
  .job-title-item{
    width: 230px;
    float: left;
    font-size: 18px;
    background-color: #EEEEEE;
    border-radius: 25px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 20px;
    cursor: pointer;
  }
  .itemActive{
    background: linear-gradient(to right, #64D0EB, #1FA8CD);
    color: white;
  }
  .job-content{
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
</style>