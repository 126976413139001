import Vue from 'vue'
import store from '../store'
import Vuex from 'vuex'
import {message} from '@/utils/resetMessage';
class Utils {
  constructor() {}
  /**
   * 深copy
   * @param obj
   * @returns {*}
   */
  deepCopy(obj) {
    let newObj
    if (typeof obj === 'object') {
      newObj = Array.isArray(obj) ? [] : {}
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          newObj[key] = this.deepCopy(obj[key])
        } else {
          newObj[key] = obj[key]
        }
      }
    } else {
      newObj = obj
    }
    return newObj
  }

  /**
   * 转换成能够和后台通信的数据格式
   * @param obj
   * @returns {*}
   */
  convertDTO(obj) {
    let body = this.deepCopy(obj)
    Object.keys(body).forEach(item => {
      body[item] = this.arrToStr(body[item])
    })
    return body
  }

  /**
   * 数组转换成以逗号隔开的字符串
   * @param arr
   * @returns {*}
   */
  arrToStr(arr) {
    if (Array.isArray(arr)) return arr.join(',')
    return arr
  }

  /**
   * 根据target复制数据
   * @param source
   * @param target
   */
  copyData(source, target) {
    Object.keys(target).forEach(item => {
      target[item] = source[item] || target[item]
    })
  }

  /**
   * 清空数据
   * @param data
   */
  clearData(data) {
    Object.keys(data).forEach(item => {
      switch (typeof data[item]) {
        case 'string':
          data[item] = ''
          break
        case 'number':
          data[item] = 0
          break
        case 'object':
          data[item] = Array.isArray(data[item]) ? [] : this.clearData(data[item])
          break
      }
    })
  }

  /**
   * 链式调用不会出现 undefined
   * @param fnc
   * @returns {string|*}
   */
  notError(fnc) {
    try {
      let temp = fnc()
      if (!temp || temp === 'Invalid Date') return ''
      return temp
    } catch (e) {
      return ''
    }
  }
  // cookie 的名字（cname），cookie 的值（cvalue），以及知道 cookie 过期的天数（exdays）
  setCookie(cname, cvalue, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  // 文章中关键字变换颜色:content:文章内容  replaceText:要替换的关键字（数组） color:要变换的颜色
  keyWordChangeColor(content, replaceText, color) {
    let text = ''
    for (let i = 0; i < replaceText.length; i++) {
      if (i != replaceText.length - 1) {
        text += replaceText[i] + '|'
      } else {
        text += replaceText[i]
      }
    }
    return content.replace(eval('/(' + text + ')/g'), function() {
      let a = arguments[0]
      return (
        '<span class="text-click" style=" color: ' + color + '; font-weight: 500;">' + a + '</span>'
      )
    })
  }
  openWindow(router, name, params,type) {
    let openWindowName = null
    let routeData = router.resolve({
      name: name,
      query: { ...params },
    })
    if(type == 'dialogWindow'){
        openWindowName = window.open(routeData.href, '_blank','channelmode=yes,width='+(window.screen.availWidth)+',height='+(window.screen.availHeight)+ ',top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no')
        setTimeout(()=>{
            if(chrome.runtime == undefined){
                return
            }
            chrome.runtime.sendMessage(document.getElementById('chrome-id').innerText, {name: 'open'},
                function(response) {
                    console.log('response==========>>',response)
                });
        },1000)
    }else{
        openWindowName = window.open(routeData.href, '_blank')
    }
    return openWindowName
  }

  formatDate(time) {
    if (null !== time) {
      var date = new Date(time)
      var YY = date.getFullYear() + '-'
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      // var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return YY + MM + DD
    } else {
      return null
    }
  }
  checkCode(val) {
    let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
    let code = val.substring(17)
    if (p.test(val)) {
      var sum = 0
      for (var i = 0; i < 17; i++) {
        sum += val[i] * factor[i]
      }
      if (parity[sum % 11] == code.toUpperCase()) {
        return true
      }
    }
    return false
  }
  getFileSuffix(filePath) {
    //获取最后一个.的位置
    let index = filePath.lastIndexOf('.')
    //获取后缀
    let ext = filePath.substr(index + 1)
    return ext
  }

  message(type, messages) {
  message({type: type,message:messages,showClose: true});
    // Vue.prototype.$message({
    //   showClose: true,
    //   message: message,
    //   type: type,
    // })
  }

  handleDocType(fileType) {
    let docType = ''
    let fileTypesDoc = [
      'doc',
      'docm',
      'docx',
      'dot',
      'dotm',
      'dotx',
      'epub',
      'fodt',
      'htm',
      'html',
      'mht',
      'odt',
      'ott',
      'pdf',
      'rtf',
      'txt',
      'djvu',
      'xps',
    ]
    let fileTypesCsv = ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
    let fileTypesPPt = [
      'fodp',
      'odp',
      'otp',
      'pot',
      'potm',
      'potx',
      'pps',
      'ppsm',
      'ppsx',
      'ppt',
      'pptm',
      'pptx',
    ]
    if (fileTypesDoc.includes(fileType)) {
      docType = 'text'
    }
    if (fileTypesCsv.includes(fileType)) {
      docType = 'spreadsheet'
    }
    if (fileTypesPPt.includes(fileType)) {
      docType = 'presentation'
    }
    return docType
  }

  debounce(fn, delay) {
    let timer = null // 借助闭包
    return () => {
      if (timer) {
        clearTimeout(timer)
        timer = setTimeout(fn, delay)
      } else {
        timer = setTimeout(fn, delay)
      }
    }
  }
  checkAuth(id) {
    let auth = JSON.parse(store.getters.getAuthorityInfo)
    for (let i = 0; i < auth.length; i++) {
      let authInfo = auth[i].authInfo
      if (authInfo && authInfo.indexOf(id) >= 0) {
        return true
      }
    }
    return false
  }
  checkPlateForm(id) {
    //1平台
    let auth = JSON.parse(store.getters.getAuthorityInfo)
    for (let i = 0; i < auth.length; i++) {
      if (auth[i].platformTypeId && auth[i].platformTypeId == id) {
        return true
      }
    }
    return false
  }
  checkButton(id){
    let auth = JSON.parse(store.getters.getAuthorityInfo)
    for (let i = 0 ; i < auth.length ;i++){
      let authInfo = auth[i].authInfo
      if (authInfo && authInfo.indexOf(id) >= 0){
        return true
      }
      return false
    }
  }
  checkRoleId(id){
    //判断角色
    let auth = JSON.parse(store.getters.getAuthorityInfo)
    for (let i = 0; i < auth.length; i++) {
      if (auth[i].roleId && auth[i].roleId == id) {
        return true
      }
    }
    return false
  }
  //判断浏览器类型
  getBrowser() {
      let userAgent = navigator.userAgent //取得浏览器的userAgent字符串
      let isOpera = userAgent.indexOf("Opera") > -1
      if (isOpera) { //判断是否Opera浏览器
          return "Opera"
      }
      if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
          return "FF";
      }
      if (userAgent.indexOf("Chrome") > -1) {
          return "Chrome";
      }
      if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
          return "Safari";
      }
      if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
          return "IE";
      }
  }
  //判断是否是uuid
  isUid(uuid){
      let reg = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/;
      let r = uuid.match(reg);
      return r
  }
}
export default Utils
